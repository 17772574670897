import React from "react";
import {navigate} from "gatsby";

import { NewsCardContent, NewsCardImg, NewsCardContainer, NewsCardDescription, NewsCardTitle, NewsImage } from './DrvNewsCard.styles';

const DrvNewsCard = ({item, index}) => {
    const title = item.title.replace(/[’:?]/g, "").replace(/ /g, "-").toLowerCase();
    return(
        <NewsCardContainer onClick={() => {
            if (item.externalLink) {
                window.open(item.externalLink, '_blank').focus();
            } else {
                navigate(`/news-details/${title}`)
            }
        }} className="container flex p-0 flex-col lg:flex-row" key={index}>
            <NewsCardImg className="">
                <NewsImage src={item.data.image} alt={item.data.alt} />
            </NewsCardImg>
            <NewsCardContent>
                <NewsCardTitle>
                    {item.title}
                </NewsCardTitle>
                <NewsCardDescription>
                    {item.data.description}
                </NewsCardDescription>
            </NewsCardContent>
        </NewsCardContainer>
    )
}

export default DrvNewsCard;
