import React, { useState }  from "react";

import DrvNewsCard from "../../Shared/DrvNewsCard/DrvNewsCard";
import Pagination from "../../Shared/DrvPagination/DrvPagination";
import DrvCover from "../../Shared/DrvCover/DrvCover";
import {DrvLinkPath} from "../../Shared/DrvLink/DrvLinkPath";
import DrvContent from "../../Shared/DrvContent/DrvContent";

import {NewsData} from './News.data';
import {Container} from "./News.styles";

import NewsImage from "../../../images/OurCompany/News/news.jpeg";

const News = () => {
    const checkType = (value) => {
        if(value === 'sessionStorage'){
            return (typeof sessionStorage !== 'undefined');
        }else if(value === 'window'){
            return (typeof window !== 'undefined');
        }
    }
    const [currentPage,setCurrentPage] = useState(checkType('sessionStorage') ? (JSON.parse(sessionStorage.getItem('page'))? JSON.parse(sessionStorage.getItem('page')):1) : 1);
    const [postsPerPage] = useState(10);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPost = NewsData.slice(indexOfFirstPost, indexOfLastPost);
    
    const paginate = (pageNumber) =>{

        if(checkType('sessionStorage')){
            sessionStorage.setItem('page',pageNumber)
        }
        setCurrentPage(pageNumber);
        if(checkType('window')){
            window.scrollTo(0, 100)
        };
    }
    const pervPage = () =>{
        setCurrentPage(currentPage-1);
        if(checkType('sessionStorage')){
            sessionStorage.setItem('page',currentPage-1)
        }
        if(checkType('window')){
            window.scrollTo(0, 100)
        };
    }
    const nextPage = () =>{
        if(checkType('sessionStorage')){
            sessionStorage.setItem('page',currentPage+1)
        }
        setCurrentPage(currentPage+1)
        if(checkType('window')){
            window.scrollTo(0, 100)
        };
    }

    return (
        <>
            <DrvCover
                title='NEWS'
                description=''
                color='#ffffff'
                image={NewsImage}
            />
            <DrvContent
                type={["text"]}
                content={[
                    (
                        <>
                            <DrvLinkPath items={[{text: 'Drivosity', link: '/', isLink: true}, {text: 'News', link: '#', isLink: false}]} />
                            <Container>
                                {
                                    currentPost.map((item, index) => (
                                        <DrvNewsCard key={index} item={item} index={item.data.id} />
                                    ))
                                }
                            </Container>
                        </>
                    )
                ]}>
            </DrvContent>
            <Pagination
                totalPosts={NewsData.length}
                postsPerPage={postsPerPage}
                paginate={paginate}
                currentPage={currentPage}
                pervPage={pervPage}
                nextPage={nextPage}
            />
        </>
    )
}

export default News;
