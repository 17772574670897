import React from "react";
import {Helmet} from "react-helmet";

import Layout from "../components/Layout";
import News from "../components/OurCompany/News/News";

import Favicon from "../images/Favicon.png";

import ogImage from "../images/Metadata/news.jpg"

const NewsPage = () => {
    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>News - Drivosity</title>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon}/>
                <meta name="description"
                    content="Stay updated with the latest news in the Last-Mile Delivery industry. Gain insights on first-party delivery and third-party delivery, safety, productivity, and industry innovations. Learn how Drivosity is leading the way in last-mile delivery solutions. Read more today."/>
                <meta name="description"
                    content="Drivosity explores how to balance first-party and third-party food delivery with a hybrid approach, optimizing customer experience and profitability. Explore key strategies for sustainable growth."/>
                <meta name="description"
                    content="Drivosity explores first-party vs third-party food delivery strategies, examining pros, cons, and driver-centric approaches to optimize restaurant delivery operations and customer experience."/>
                <meta name="description"
                    content="Drivosity addresses hired and non-owned auto insurance in food delivery, offering safety-driven solutions that cut costs and protect drivers."/>

                <meta property="og:title" content="News - Drivosity"/>
                <meta property="og:description" content="Stay updated with the latest news in the Last-Mile Delivery industry. Gain insights on first-party delivery and third-party delivery, safety, productivity, and industry innovations. Learn how Drivosity is leading the way in last-mile delivery solutions. Read more today."/>
                <meta property="og:image" content={`${process.env.GATSBY_SITE_URL}${ogImage}`}/>
                <meta property="og:image:alt" content="Stack of documents and reports on a laptop keyboard, symbolizing business news, reports, and research."/>
                <meta property="og:type" content="website"/>
                <meta property="og:url" content={`${process.env.GATSBY_SITE_URL}/news`}/>
            </Helmet>
            <Layout component={<News/>}/>
        </>
    )
}

export default NewsPage;
